import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink, CSVDownload } from "react-csv";

const exportHeaders = [
  { label: "Oligo Sequence", key: "oligo_sequence" },
  { label: "Oligo name", key: "oligo_name" },
  { label: "Researcher Name", key: "researcher_name" },
  { label: "Synthesis scale", key: "synthesis_scale" },
  { label: "5' Mod", key: "5_mod" },
  { label: "3' Mod", key: "3_mod" },
  { label: "Purification", key: "purification" },
  { label: "Special Handling", key: "special_handling" }
];

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      minHeight: "40px",
      backgroundColor: "#F7F7F7"
    },
    deleteContainer: {
      display: "flex",
      alignItems: "center"
    },
    deleteLink: {
      fontWeight: "bold",
      color: "#208AE7",
      textDecoration: "none"
    },
    exportOrder: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontWeight: "bold",
      color: "#208AE7"
    },
    exportIcon: {
      marginLeft: "5px"
    },
    tableContainer: {
        width: 980
    },
    tableHeader: {
      borderBottom: "2px solid #dee2e6",
      height: "40px"
        
    },
    tableHeaderCell: {
        fontWeight: "bold"
    },
    oligoSequence: {
      minWidth: "300px"
    },
    m13: {
        color: "#E71316"
    },
    title: {
  
    },
    '@global': {
      // You should target [class*="MuiButton-root"] instead if you nest themes.
      '.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
        backgroundColor: "#EAF2FA"
      },
      '.MuiCheckbox-root.Mui-checked': {
        color: "#208AE7"
      },
      '.MuiIconButton-colorSecondary:hover, .MuiIconButton-colorSecondary:active': {
        backgroundColor: "none"
      }
    }
    
  });

  

export default function OligoTable({showTable, data, deletePrimerFunc, exportData}) {
    


    const classes = useStyles();

    
    const tableHeaders = [ { id: 1, name: "Oligo Sequence"}, 
    { id: 2, name: "Oligo Name"} ];

    const [selected, setSelected] = React.useState([]);
    const [selectAllChecked, setSelectAllChecked] = React.useState(false);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelecteds = data.map((n) => n.oligo_name);
          console.log("*** newSelecteds: ", newSelecteds);
          setSelected(newSelecteds);
          setSelectAllChecked(true);
          return;
        } else {
          setSelectAllChecked(false);
        }
        setSelected([]);
      };

    const handleDelete = (event) => {
      deletePrimerFunc(selected);
      if (selectAllChecked) {
        setSelectAllChecked(false);
      }
      setSelected([]);
      console.log("*** tabledata size", data);
    }
    
    const handleClick = (event, name) => {
       
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
    
        console.log("*** new selected", newSelected);
        setSelected(newSelected);
      };
    
    const isSelected = (name) => selected.indexOf(name) !== -1;

    useEffect(()=>{
      console.log("*** useEffect data", data);
      if (data.length === 0) {
        setSelected([]);
        setSelectAllChecked(false);
      }
    },[data])

    return(
        <>
        {showTable && data &&

              
            <TableContainer className={classes.tableContainer}>
              
                <Toolbar className={classes.toolbar}>
                  {selected.length > 0 ? (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                      {selected.length} rows selected
                    </Typography>) 
                    :
                    <Typography></Typography>
                  }
                  {selected.length > 0 ? (
                    <div className={classes.deleteContainer}>
                    
                    <a href="#" className={classes.deleteLink} onClick={handleDelete}>
                    <Typography><strong>Delete selected</strong></Typography></a></div>) : 
                  
                   
                    <CSVLink className={classes.exportOrder} filename={"tf-order.csv"} data={exportData} headers={exportHeaders}>
                      <Typography><strong>Export to Order</strong></Typography>
                    </CSVLink>  }
                  </Toolbar>
                <Table className={classes.table}>
                    <TableHead >
                        
                        <TableRow className={classes.tableHeader}>
                            
                            <TableCell padding="checkbox">
                                <Checkbox checked={selectAllChecked} onChange={handleSelectAllClick} />
                             </TableCell>
                            {tableHeaders.map((tableHeader) => {
                                return(    
                                    <>
                                        <TableCell className={classes.tableHeaderCell} key={tableHeader.id}>{tableHeader.name}</TableCell>
                                    </>
                                )})}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => {
                            const isItemSelected = isSelected(row.oligo_name);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (<TableRow 
                                key={row.oligo_name}
                                onClick={(event) => handleClick(event, row.oligo_name)}
                                selected={isItemSelected}
                                className={classes.tableRow}
                                >
                                <TableCell padding="checkbox">
                                    <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </TableCell>
                                <TableCell className={classes.oligoSequence}>
                                <span className={classes.m13}>{row.fwd ? row.fm13 : row.rm13}</span>{row.oligo_sequence}
                                </TableCell>
                                <TableCell>
                                {row.oligo_name}
                                </TableCell>
                            </TableRow>
                            )})}
                    </TableBody>
                </Table>
            </TableContainer>
            
}
        </>
    );

}