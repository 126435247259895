import React, { useState, useEffect } from 'react';
import { AppBar } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import OligoTable from '../OligoTable/index';
import OligoGrid from '../OligoGrid/index';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import { Snackbar } from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import clsx from  'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        
      },
    appBar: {
        backgroundColor: "#343535",
        borderBottom: "2px solid #EB3537"
    },
    toolBar: {
        minHeight: 42
    },
    menuButton: {
        marginRight: theme.spacing(2),
      },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    gCoords: {
        color: "red",
        marginTop: theme.spacing(3.6)
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    clearButton: {
        textTransform: "none",
        marginRight: theme.spacing(2),
        color: "#FFFFFF",
        border: "1px solid #555759",
        backgroundColor: "#555759",
        '&:hover': {
            border: "1px solid #222222",
            backgroundColor: "#222222"
         },
    },
    strainSelect: {
        minWidth: "155px"
    },
    gCoordsInput: {
        width: "175px"
    },
    aaChangeInput: {
        width: "150px"
    },
    aaChangeHelper: {
        width: "125px"
    },
    aaChangeForm: {
        marginRight: "4px",
        marginTop: theme.spacing(6.2)
    },
    primerForm: {
        marginRight: "4px",
        marginTop: theme.spacing(3.6)
    },
    primerInput: {
        width: "150px"
    },
    m13tags: {
        marginTop: "20px",
        marginLeft: "0px"
    },
    searchButton: {
        textTransform: "none",
        marginRight: theme.spacing(2),
        backgroundColor: "#E71316",
        border: "1px solid #E71316",
        '&:hover': {
            border: "1px solid #D01013",
            backgroundColor: "#D01013"
         },
    },
    lastUpdated: {
        color: "#555759"
    },
    gridStyle: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    '@global': {
        '.MuiOutlinedInput-root': {
            borderRadius: "0px",
            height: "40px",
            textAlign: "left"
        },
        '.MuiSelect-select:focus': {
            backgroundColor: "transparent"
        },
        '.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
            backgroundColor: "#1E8AE7",
            color: "white"
        },
        '.MuiListItem-root:hover': {
            backgroundColor: "#EBF2FA"
        }
    }

    }));

export default function Main() {

    const NO_STRAIN = "None";
    const NO_GENE = "None"
    const classes = useStyles();
    const [strain, setStrain] = React.useState(NO_STRAIN);
    const [gene, setGene] = React.useState(NO_GENE);
    const [geneNames, setGeneNames] = React.useState([]);
    const [strainNames, setStrainNames] = React.useState([]);
    const [aaChange, setAAChange] = React.useState('');
    const [m13, setM13] = React.useState(false);
    const [gCoords, setGCoords] = React.useState('');
    const [primer, setPrimer] = React.useState('');
    const [showTable, setShowTable] = React.useState(false);
    const [data,setData]=useState([]); // data from json file
    const [tableSearchKeys, setTableSearchKeys] = React.useState([]);  
    const [tableData, setTableData] = React.useState([]);
    const [exportData, setExportData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [snackbarMsg, setSnackbarMessage] = React.useState('');
    const [lastUpdated, setLastUpdated] = React.useState('');
    

    const F_M13 = "TGTAAAACGACGGCCAGT";
    const R_M13 = "CAGGAAACAGCTATGACC"
    const REGEX_AAChange = /[a-zA-Z*]{1}(\d+)[a-zA-Z*]{1}/ig;
    const REGEX_DelAAChange = /(del|DEL)(\d+)\/\d+/g;
    const REGEX_DelAAChange2 = /(del|DEL)[a-zA-Z*]+(\d+)/g;
    const REGEX_IUPAC = /[acdefghiklmnpqrstvwyACDEFGHIKLMNPQRSTVWY*]{1}(\d+)[acdefghiklmnpqrstvwyACDEFGHIKLMNPQRSTVWY*]{1}/ig;

    const CHOOSE_GENE_ERROR_MSG = "Please choose a gene for this mutation";

    const GENOME_SIZE = 29903;
    const PRIMER_BUFFER = 100;
    const LAST_UPDATED = 1638913498 * 1000;

    const units = {
        year  : 24 * 60 * 60 * 1000 * 365,
        month : 24 * 60 * 60 * 1000 * 365/12,
        day   : 24 * 60 * 60 * 1000,
        hour  : 60 * 60 * 1000,
        minute: 60 * 1000,
        second: 1000
      }

    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

    const getRelativeTime = (d1, d2 = new Date()) => {
        let elapsed = d1 - d2
      console.log("*** elapsed", elapsed);
        // "Math.abs" accounts for both "past" & "future" scenarios
        for (let u in units) {
          if (Math.abs(elapsed) > units[u] || u == 'second') {
                return rtf.format(Math.round(elapsed/units[u]), u)
          }
        }
    }

      
      const getNewData=()=> {
        fetch('data2.json'
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then(function(response){
              console.log("new data response", response);
            return response.json();
          })
          .then(function(myJson) {
              console.log("Data2 json", myJson);
            
            setData(myJson);
            populateStrainsAndGenes();
            console.log("**** genenames", geneNames);
          });
      }

      const getData=()=>{
        fetch('data.json'
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then(function(response){
            console.log(response)
            return response.json();
          })
          .then(function(myJson) {
            console.log(myJson);
            setData(myJson)
          });
      }
      useEffect(()=>{
        // getData()
        console.log("*** calling get new data");
        getNewData()
      },[])

      useEffect(()=>{
        console.log("*** calling populate genes");
        populateStrainsAndGenes();
        setLastUpdated(getRelativeTime(new Date(LAST_UPDATED)));
      },[data])


    const handleStrainChange = (event) => {
        setStrain(event.target.value);
    };

    const handleGeneChange = (event) => {
        setGene(event.target.value);
        console.log("Gene: ", event.target.value);
    };

    const handleAAChange = (event) => {
        setAAChange(event.target.value);
    };

    const handleGCoordsChange = (event) => {
        setGCoords(event.target.value);
    }
    
    const handlePrimerChange = (event) => {
        setPrimer(event.target.value);
    }
    
    const handleM13Change = (event) => {
        console.log("setM13", event.target.checked)
        setM13(event.target.checked);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    const isGeneExistInStrain = (strainName, geneName) => {

        let found = false;
        data["strains"].forEach(strain => {
            if (strain["name"] === strainName) {
                console.log("found strain!", geneName);

                console.log(strain["genes"]);
                
                found = strain["genes"].includes(geneName);
            } 
        });

        return found;
    }

    // grabbed from https://stackoverflow.com/questions/4340227/sort-mixed-alpha-numeric-array
    const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true })

    const populateStrainsAndGenes = () => {
    

        if (data.length === 0) {
            return;
        }
        let genes = [NO_GENE];
        let strains = [];
        data["genes"].forEach(gene => {
            if (!genes.includes(gene["name"])) {
                genes.push(gene["name"]);
            } 

            if (!strains.includes(gene["strain"])) {
                strains.push(gene["strain"]);
            }
        });

        setGeneNames(genes);
        strains = strains.sort(sortAlphaNum);
        strains.unshift(NO_STRAIN);
        setStrainNames(strains);
    };

    const getGeneDataForStrain = (geneName) => {

        // get the gene data for the strain
        let geneData = null;
        data["genes"].forEach(gene => {

            if (gene["name"] === geneName && gene["strain"] === strain) {
                geneData = gene;
            }
        });
        return geneData;
    }

    const getGeneList = (geneName) => {

        // get the gene data for the strain
        let geneList = [];
        data["genes"].forEach(gene => {

            if (gene["name"] === geneName) {
                geneList.push(gene);
            }

        });
        return geneList;
    }

    const getAllAAChangeData = (aaChangeList) => {
        
        let aaChangeDataList = [];
        aaChangeList.forEach(aaChange => {
            aaChangeDataList.push(aaChange);
        });
        return aaChangeDataList;
    }

    const getAAChangeData = (aaChangeList, aaChangeName) => {

        let aaChangeData = null;
        aaChangeList.forEach(aaChange => {
            if (aaChange["name"] === aaChangeName) {
                aaChangeData = aaChange;
            }
        });
        return aaChangeData;
    }

    const getPrimerDataByGCoords = (geneCoordinate) => {

        console.log("*** gCOords", geneCoordinate);
        let gc = geneCoordinate.split("-"); // gc[0] contains start range, gc[1] contains end range
        console.log("*** gc[1]", gc[1]);
        
        if (gc[1] === undefined) {
            gc.push(gc[0]);
        }

        if (gc[0] < 1) {
            throw new Error("No primers found. Please check that genomic coordinates is a number greater than 0");
        } 
        
        if (gc[0] > GENOME_SIZE) {
            throw new Error("No primers found. Please check that genomic coordinates do not exceed the size of the SARS-Cov-2 genome")
        }

        console.log("*** gc", gc);

        let primerData = [];
        data["primers"].forEach(primer => {
            const start = primer.start + primer.fseq.length + (m13 ? 25 : 40);
            const end = primer.end - primer.rseq.length;

            console.log("*** primer: " + primer.name + " start: " + start + " end: " + end + " geneCoords: " + geneCoordinate);

            if (gc[0] == gc[1]) {
                if (gc[0] >= start && gc[1] <= end) {
                    primerData.push(primer);
                }
            } else {
                
                if ( end < gc[0] || gc[1] < start) {
                    // if true, then no overlap
                    // https://stackoverflow.com/questions/17148839/overlapping-line-segments-in-2d-space
                } else {
                    primerData.push(primer)
                }
            }

        });
        return primerData;
    }

    const getPrimerDataByName = (primerName) => {

        // let user search by primer name, primer fname and primer rname
        let primerData = []
        data["primers"].forEach(primer => {

            if (primer.name === primerName ||
                    primer.fname.split("_")[0] === primerName ||
                    primer.rname.split("_")[0] === primerName) {
                primerData.push(primer);
            }
        });
        return primerData;
    }

    const lookupAACoordinates = ( geneStart, aaStart ) => {

        console.log("lookup gCoords for", geneStart, aaStart);
        return geneStart + (3 * aaStart) - 3;
    }

    const validateAAChange = () => {
        // validate the AAChange

        if ((aaChange.startsWith("del") || aaChange.startsWith("DEL")) && aaChange.indexOf("/") !== -1 ) {
            if (!aaChange.match(REGEX_DelAAChange)) {
                throw new Error("No primers found.  Please enter amino acid change in DEL###/###.0 format.");
            }
            
        } else if (aaChange.startsWith("del") || aaChange.startsWith("DEL")) {
            if (!aaChange.match(REGEX_DelAAChange2)) {
                throw new Error("No primers found.  Please enter amino acid change in DEL### format.");
            }
        } else {
            if (!aaChange.match(REGEX_AAChange)) {
                throw new Error("No primers found.  Please enter amino acid change in X###Z format.");
            }

            if (!aaChange.match(REGEX_IUPAC)) {
                throw new Error("No primers found. " + aaChange + " is not a valid amino acid change.");
            }
        }
    }

    const doAAChangeSearch = () => {

        validateAAChange();

        // find the first matchin gaaChange in the list and return it

        for (let i = 0; i < data["genes"].length; i++) {

            let gene = data["genes"][i];

            for (let j = 0; j < gene.aaChangeList.length; j++) {

                let theAAChange = gene.aaChangeList[j];
                if (theAAChange.name === aaChange) {          
                    const aaStart = lookupAACoordinates(gene.start, theAAChange.start);
                    return getPrimerDataByGCoords(aaStart.toString()).slice(0, 1);
                }
            }
        }

        if (gene === NO_GENE) {
            throw new Error(CHOOSE_GENE_ERROR_MSG);
        }
    }


    const doStrainAAChangeSearch = () => {

        validateAAChange();

        const geneList = getGeneDataForStrain(gene);
        console.log("*** strainAA search", geneList);
        // we're returning the first gene/aaChange match that we find since they should all be the same 
        for (let i = 0; i < data["genes"].length; i++) {
            let geneData = data["genes"][i];

            if (geneData.strain === strain) {
                for (let j = 0; j < geneData.aaChangeList.length; j++) {
                    let theAAChange = geneData.aaChangeList[j];
                    
                    if (theAAChange.name === aaChange) {          
                        const aaStart = lookupAACoordinates(geneData.start, theAAChange.start);
                        return getPrimerDataByGCoords(aaStart.toString()).slice(0, 1);
                    }
                }
            }

        }

        return null;
    }


    const doGeneAAChangeSearch = () => {

        validateAAChange();

        const geneList = getGeneList(gene);

        // we're returning the first gene/aaChange match that we find since they should all be the same 
        for (let i = 0; i < geneList.length; i++) {
            let geneData = geneList[i];
            for (let j = 0; j < geneData.aaChangeList.length; j++) {
                let theAAChange = geneData.aaChangeList[j];
                
                if (theAAChange.name === aaChange) {          
                    const aaStart = lookupAACoordinates(geneData.start, theAAChange.start);
                    return getPrimerDataByGCoords(aaStart.toString()).slice(0, 1);
                }
            }
        }

        let geneStart = geneList[0].start;
        let geneEnd = geneList[0].end;
        return lookupPrimerByNonMatchingAAChange(geneStart, geneEnd);
    }

    const lookupPrimerByNonMatchingAAChange = (geneStart, geneEnd) => {
        // Input AAChange doesn't match any mutation in DB.  Use formula to look up primer
        if (aaChange.startsWith("del") || aaChange.startsWith("DEL")) {
            let match = REGEX_DelAAChange.exec(aaChange);
            if (!match) {
                match = REGEX_DelAAChange2.exec(aaChange);
            }
            const aaStart = lookupAACoordinates(geneStart, match[2]);
            if (aaStart < geneStart || aaStart > geneEnd) {
                throw new Error(aaChange + " is not within bounds of gene " + gene);
            }
            return getPrimerDataByGCoords(aaStart.toString()).slice(0, 1);
        } else {
            let match = REGEX_AAChange.exec(aaChange);
            const aaStart = lookupAACoordinates(geneStart, match[1]);
            if (aaStart < geneStart || aaStart > geneEnd) {
                throw new Error(aaChange + " is not within bounds of gene " + gene);
            }
            return getPrimerDataByGCoords(aaStart.toString()).slice(0, 1);
            
        }
    }

    const doStrainGeneAChangeSearch = () => {

        validateAAChange();

        let primerDataList = [];
        // lookup gene
        const geneData = getGeneDataForStrain(gene);
        if (geneData === null) {
            return null;
        }
        
        console.log("gene data", geneData);
        if (geneData.strain === strain) {
            const aaChangeData = getAAChangeData(geneData.aaChangeList, aaChange);
            if (aaChangeData === null) {
                return lookupPrimerByNonMatchingAAChange(geneData.start, geneData.end);
            }
            const aaStart = lookupAACoordinates(geneData.start, aaChangeData.start);
            console.log("aaStart", aaStart);
            // return the 1st one in the list
            primerDataList = getPrimerDataByGCoords(aaStart.toString()).slice(0, 1);
            
        }

        return primerDataList;
    }

    const getPrimersForAAChange = (geneData) => {

        let res = [];
        const aaChangeList = getAllAAChangeData(geneData.aaChangeList);
        aaChangeList.forEach(aaChangeData => {
            const aaStart = lookupAACoordinates(geneData.start, aaChangeData.start);
            const primerDataList = getPrimerDataByGCoords(aaStart.toString());
            primerDataList.forEach(primerData => {
                // if primer doesn't exist in primerDataList, then add
                let found = false;
                res.forEach(elem => {
                    if (elem.name === primerData.name) {
                        found = true;
                    }
                });
                if (!found) {
                    res = res.concat(primerData);
                }
            });
        });
        return res;
    }

    const getPrimersForGene = (geneData) => {


        let res = [];

        data["primers"].forEach(primerData => {

            if ( primerData.end < geneData.start - PRIMER_BUFFER || geneData.end + PRIMER_BUFFER < primerData.start) {
                //no overlap
            } else {
                let found = false;
                res.forEach(elem => {
                    if ((elem.name + elem.fname + elem.rname) === (primerData.name + primerData.fname + primerData.rname)) {
                        found = true;
                    }
                });
                if (!found) {
                    res = res.concat(primerData);
                }
            }
        });

        return res;
    }

    const doStrainSearch = (strainName) => {
        let res = [];
        console.log("*** doing strain search ", strainName);
        data["genes"].forEach(geneData => {
            if (geneData["strain"] === strainName) {
                console.log("*** found strain for ", geneData);
                let primersFound = getPrimersForAAChange(geneData);
                res = res.concat(primersFound);
            }
        });
        return res;

    }

    const doGeneSearch = (geneName) => {
        let res = [];
        console.log("*** doing gene search");
        data["genes"].forEach(geneData => {

            if (geneData["name"] === geneName) {
                let primersFound = getPrimersForGene(geneData);
                res = res.concat(primersFound);
            }
        });
        return res;
        
    }

    const doStrainAndGeneSearch = (strain, geneName) => {
        let res = []
        console.log("*** doing strain and gene search");
        data["genes"].forEach(geneData => {

            if (geneData["name"] === geneName && geneData["strain"] === strain) {
                let primersFound = getPrimersForGene(geneData);
                res = res.concat(primersFound);
            }
        });
        return res;
    }

    const showNotification = (msg) => {
        setSnackbarMessage(msg);
        console.log("*** setting open true");
        setOpen(true);
    }

    const deletePrimers = (primersToDelete) => {
        console.log("delete primers", primersToDelete);
        
        let newTableData = [];
        newTableData = tableData.filter( ( el ) => !primersToDelete.includes( el.oligo_name ) );
        if (newTableData.length === 0) {
            setShowTable(false);
        }
        setTableData(newTableData);
        prepareExportData(newTableData);
    }

    const doPrimersExistInTable = (primerData) => {

        const tableNames = [];
        const existing = [];

        console.log("*** tableData", tableData);
        tableData.forEach(td => {
            tableNames.push(td.oligo_name);
        });
        
        console.log("*** tableNames", tableNames);
        primerData.forEach(primer => {
            if (tableNames.includes(primer.fname + (m13 ? "_M13" : ""))) {
                existing.push(primer.fname + (m13 ? "_M13" : ""));
            } 
            if (tableNames.includes(primer.rname + (m13 ? "_M13" : ""))) {
                existing.push(primer.rname + (m13 ? "_M13" : ""));
            }
        });
        
        return existing;
    }

    const handleSearch2 = (event) => {
        
        // let searchKey = strain + gene + aaChange + m13;
        // if (tableSearchKeys.includes(searchKey)) {
        //     showNotification("Primers already exist in the table");
        //     console.log("Search key " + searchKey + " already exists in the table....");
        //     return;
        // }

        let newTableData = [];
        let primerData = [];

        if (primer != '') {
            primerData = getPrimerDataByName(primer);
            setStrain(NO_STRAIN);
            setGene(NO_GENE);
            setAAChange('');
            setGCoords('');
        } else if (gCoords != '') {
            try {
                primerData = getPrimerDataByGCoords(gCoords.toString());
                setStrain(NO_STRAIN);
                setGene(NO_GENE);
                setAAChange('');
            } catch(e) {
                showNotification(e.message);
                return;
            }
        } else if (strain === NO_STRAIN && gene === NO_GENE && aaChange !== '') {
            showNotification(CHOOSE_GENE_ERROR_MSG);
            return;
            // try {
            //     primerData = doAAChangeSearch();
            // } catch (e) {
            //     showNotification(e.message);
            //     return;
            // }
        } else if (strain !== '' && strain !== NO_STRAIN && gene === NO_GENE && aaChange != '') {
            showNotification(CHOOSE_GENE_ERROR_MSG);
            return;
            // try {
            //     primerData = doStrainAAChangeSearch();
            // } catch (e) {
            //     showNotification(e.message);
            //     return;
            // }
        } else if (strain === NO_STRAIN && gene !== '' && aaChange !=='') {
            try {
                primerData = doGeneAAChangeSearch();
            } catch (e) {
                showNotification(e.message);
                return;
            }
        }  else if (strain !== '' && strain !== NO_STRAIN && gene !== '' && aaChange !=='') {
            try {
                primerData = doStrainGeneAChangeSearch();
            } catch (e) {
                showNotification(e.message);
                return;
            }

            
        } else if (strain !== '' && strain !== NO_STRAIN && gene !== '' && gene !== NO_GENE) {
            primerData = doStrainAndGeneSearch(strain, gene);
        } else if (strain !== '' && strain !== NO_STRAIN) {
            primerData = doStrainSearch(strain);
        } else if (gene !== '' && gene !== NO_GENE) {
            primerData = doGeneSearch(gene);
        } 
        else {
            console.log("unknown search");
        }

        if (primerData == null || primerData.length === 0) {
            showNotification("No primers found. Please check that your search parameters are correct.");
            return;
        }

        // remove duplicates
        // https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
        primerData = primerData.filter((value,index,arr)=>arr.findIndex(t=>(t.name === value.name && t.fname === value.fname))===index);

        // sort primers by name
        primerData.sort(function(a, b) {
            let nameA = parseInt(a.name.split("-")[1], 10);
            let nameB = parseInt(b.name.split("-")[1], 10);
            return nameA - nameB;
          });

        setShowTable(true);

        console.log("*** primerData", primerData);
        let existing = doPrimersExistInTable(primerData);

        primerData.forEach(data => {

            if (!existing.includes(data.fname + (m13 ? "_M13" : ""))) {
                newTableData.push( {
                    id: newTableData.length,
                    fwd: true,
                    fm13: m13 ? F_M13 : "",
                    oligo_sequence: data.fseq,
                    oligo_name: data.fname + (m13 ? "_M13" : "")
                });
            }

            if (!existing.includes(data.rname + (m13 ? "_M13" : ""))) {
                newTableData.push( {
                    id: newTableData.length,
                    fwd: false,
                    rm13: m13 ? R_M13 : "",
                    oligo_sequence: data.rseq,
                    oligo_name: data.rname + (m13 ? "_M13" : "")
                });
            }

        });

        if (existing.length > 0) {
            showNotification(existing.join(", ") + " already exists in table")
        }
 
        // tableSearchKeys.push(searchKey);

        console.log("*** newtabledata", newTableData);
        // setTableSearchKeys(tableSearchKeys);

        let updatedTableData = tableData.concat(newTableData);
        setTableData(updatedTableData);
        
        prepareExportData(updatedTableData);
    }

    const prepareExportData = (updatedTableData) => {
        let exportData = updatedTableData.map((row) => {
            return {
                oligo_sequence: (row.fwd ? row.fm13 : row.rm13) + row.oligo_sequence,
                oligo_name: row.oligo_name,
                researcher_name: "Jane Doe",
                synthesis_scale: "25N",
                purification: "DSL"
            }
        });
        console.log("Exportdata", exportData);
        setExportData(exportData);
    }

    const handleClear = (event) => {
        setShowTable(false);
        setTableData([]);
        setTableSearchKeys([]);
        setStrain(NO_STRAIN);
        setGene(NO_GENE);
        setAAChange('');
        setGCoords('');
        setPrimer('');
    }; 

    const handleDownloadToCsv = (event) => {
        setShowTable(false);
    }; 

    const theme = createMuiTheme({

        typography: {
            fontFamily: 'HelveticaNeue',
        }
    
      });

    return (
        <MuiThemeProvider theme={theme}>
            <h3>SARS-CoV-2 Sanger Primer Pair Lookup App</h3>

            <Grid container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.gridStyle}
                >


            <FormControl variant="outlined" className={classes.formControl}>
            <FormHelperText>Strain</FormHelperText>
                <Select className={classes.strainSelect} onChange={handleStrainChange} value={strain} variant="outlined">
                    { strainNames.map((value, index) => {
                        return <MenuItem key={index} value={value}>{value}</MenuItem>
                    })}
                </Select>
                
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                 <FormHelperText>Gene</FormHelperText>
                 <Select onChange={handleGeneChange} value={gene} variant="outlined">
                    { geneNames.map((value, index) => {
                        return <MenuItem key={index} value={value}>{value}</MenuItem>
                    })}

                 </Select>
                {/* <TextField onChange={handleGeneChange} value={gene} variant="outlined"></TextField> */}
            </FormControl>

            <FormControl variant="outlined" className={clsx(classes.formControl, classes.aaChangeForm)}>
                 <FormHelperText>Amino Acid Change</FormHelperText>
                <TextField className={classes.aaChangeInput} onChange={handleAAChange} value={aaChange} variant="outlined"></TextField>
                <FormHelperText className={classes.aaChangeHelper}>Enter Gene and known amino acid change</FormHelperText>
            </FormControl>

            <FormControl variant="outlined" className={clsx(classes.formControl, classes.gCoords)}>
                 <FormHelperText>Genomic Coordinates </FormHelperText>
                <TextField className={classes.gCoordsInput} onChange={handleGCoordsChange} value={gCoords} variant="outlined"></TextField>
                <FormHelperText>Based on NC_045512.2 </FormHelperText>
            </FormControl>

            <FormControl variant="outlined" className={clsx(classes.formControl, classes.primerForm)}>
                 <FormHelperText>Amplicon Name</FormHelperText>
                <TextField className={classes.primerInput} onChange={handlePrimerChange} value={primer} variant="outlined"></TextField>
                <FormHelperText>ex. SC2M1-6</FormHelperText>
            </FormControl>

            <FormControlLabel className={classes.m13tags} control={<Checkbox onChange={handleM13Change} value={m13} name="checkedC" />} label="Add M13 tags?" />

            </Grid>

            <Grid container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.gridStyle}
                >
                <Button className={classes.clearButton} onClick={handleClear} variant="outlined" color="secondary">
                Clear
                </Button>

                <Button className={classes.searchButton} onClick={handleSearch2} variant="contained" color="primary">
                Search
                </Button>
            </Grid>

            <Grid container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.gridStyle}
                >

                {/* <OligoGrid showTable={showTable} data={tableData}></OligoGrid> */}
                <OligoTable 
                    showTable={showTable} 
                    data={tableData} 
                    deletePrimerFunc={deletePrimers}
                    exportData={exportData}
                    >


                </OligoTable>
            </Grid>

            
            <Typography className={classes.lastUpdated} variant="caption" component="h2">Last Updated {lastUpdated}</Typography>

            <Snackbar
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackbarMsg}
            />
            

         
        </MuiThemeProvider>
    );
}